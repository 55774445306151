import { Box, Container } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import imgHero from "../../images/botox-screening.webp";
import imgBrand from "../../images/brand.svg";
import HowItWorks from "../../components/HowItWorks";
import DemoHero from "../../components/DemoHero";
import DermatologistsTestimonials from "../../components/DermatologistsTestimonials";

const Potozkin = () => {
  return (
    <>
      <Helmet>
        <title>SignUp | Bonsai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 80,
          }}
        >
          <Box component="img" src={imgBrand} alt="logo" sx={{ position: "relative", height: { xs: 28 } }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="mailto:sales@bonsaihealth.com" className="nav-link">
              sales@bonsaihealth.com
            </Link>
          </Box>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#F1EEFF", py: { xs: 4, lg: 9 } }}>
        <Container>
          <DemoHero
            heroImg={imgHero}
            descriptionMaxWidth={374}
            heroHeight={512}
            heading="Sign up for the Webinar"
            detail="Please provide the following and we will send you an invite to the Webinar or for those that can’t make the date we can schedule an alternative time."
            partner="Potozkin"
          />
          <DermatologistsTestimonials />
          <HowItWorks />
        </Container>
      </Box>
    </>
  );
};

export default Potozkin;
